<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Datos fiscales</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialog = true"
              small
              rounded
            >
              Agregar
            </v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="datosFiscales"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_forma_pago }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
              <v-autocomplete
              	filled
                dense
                clearable
                v-model="editedItem.id_alumno"
                :items="alumnos"
                label="Selecciona el alumno"
                persistent-hint
                hide-details
                item-text="datos_completos"
                item-value="id_alumno"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.razon_social"
                label="Razón Social"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.rfc"
                label="RFC"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
              	filled
                dense
                clearable
                v-model="editedItem.regimen_fiscal"
                :items="regimen"
                label="Selecciona regimen"
                hide-details
                item-text="regimen"
                item-value="idregimen_fiscal"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.direccion"
                label="Dirección"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
              	filled
                dense
                clearable
                v-model="editedItem.idmunicipios"
                :items="municipios"
                label="Selecciona municpio"
                hide-details
                item-text="nombre"
                item-value="idmunicipios"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.cp"
                label="CP"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.telefono"
                label="Teléfono"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="editedItem.correo"
                label="Correo"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            dark
            small
            rounded
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            small
            rounded
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  export default {
  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
      	iddatosfiscales:0,
        id_alumno:0,
				rfc:'',
				regimen_fiscal:'',
				direccion:'',
				idmunicipios:'',
				cp:'',
				telefono:'',
				correo:'',
        razon_social:'',
      },

      defaultItem: {
        iddatosfiscales:0,
        id_alumno:0,
				rfc:'',
				regimen_fiscal:'',
				direccion:'',
				idmunicipios:'',
				cp:'',
				telefono:'',
				correo:'',
        razon_social:'',
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      datosFiscales: [],
      headers: [
        { text: 'ID'           , value: 'iddatosfiscales'  },
        { text: 'Alumno'       , value: 'alumno'           },
        { text: 'Razón social' , value: 'razon_social'     },
        { text: 'RFC'          , value: 'RFC'              },
        { text: 'Regimen'      , value: 'regimen'          },
        { text: 'Dirección'    , value: 'direccion'        },
        { text: 'Municipio'    , value: 'municipio'        },
        { text: 'CP'           , value: 'cp'               },
        { text: 'Teléfono'     , value: 'telefono'         },
        { text: 'Correo'       , value: 'correo'           },
        { text: 'Actions'      , value: 'actions', sortable: false },
      ],

      alumnos:[],
      municipios:[],
      regimen:[],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar datos fiscales' : 'Editar datos fiscales'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created () {
      await this.initialize()
      await this.getAlumnosFiscales()
      await this.getMunicipios()
      await this.getRegimen()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.datosFiscales = []
        return this.$http.get('facturas.list').then(response=>{
        	this.datosFiscales = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnosFiscales () {
      	this.cargar = true
        this.alumnos = []
        return this.$http.get('alumnos.facturas.list').then(response=>{
        	this.alumnos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getMunicipios () {
      	this.cargar = true
        this.municipios = []
        return this.$http.get('facturas.municipios').then(response=>{
        	this.municipios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getRegimen () {
      	this.cargar = true
        this.regimen = []
        return this.$http.get('facturas.regimen.fiscal').then(response=>{
        	this.regimen = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.datosFiscales.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.datosFiscales.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('datosFiscales.eliminar/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('datos.fiscales.update/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('datos.fiscales.add', this.editedItem).then(response=>{
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
        this.close()
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},
    },
  }
</script>


